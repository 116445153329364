import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
    <Layout>
        <SEO title="Pimmelband | AGB" />
        <div class="pt-20 pb-3 bg-gray-200">
            <h1 class="text-center text-5xl">Allgemeine Geschäftsbedingungen (AGB)</h1>
            <ul class="list-disc">
                <li>Geltungsbereich</li>
                <li>Vertragspartner</li>
                <li>Vertragsschluss</li>
                <li>Widerrufsrecht</li>
                <li>Preise und Versandkosten</li>
                <li>Lieferung</li>
                <li>Zahlung</li>
                <li>Eigentumsvorbehalt</li>
                <li>Streitbeilegung</li>
            </ul>
        </div>
        
        <div class="space-y-2 pl-10 w-5/6">
            <h2 class="text-3xl font-bold">1. Geltungsbereich</h2>
            <p>Für alle Lieferungen von Stritago GmbH an Verbraucher gelten diese Allgemeinen Geschäftsbedingungen (AGB).
            Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der überwiegend weder ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.
            </p>

            <h2 class="text-3xl font-bold">2. Vertragspartner</h2>
            <p>Der Kaufvertrag kommt zustande mit Stritago GmbH, Fischstraße 29-35, 23552 Lübeck, Handelsregister: Amtsgericht Lübeck, HRB 16569 HL.</p>

            <h2 class="text-3xl font-bold">3. Vertragsschluss</h2>
            <p>Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes Angebot, sondern nur eine Aufforderung zur Bestellung dar.</p>
            <p>Durch Anklicken des Buttons Zahlungspflichtig bestellen geben Sie eine verbindliche Bestellung der auf der Bestellseite aufgelisteten Waren ab. Ihr Kaufvertrag kommt zustande, wenn wir Ihre Bestellung durch eine Auftragsbestätigung per E-Mail unmittelbar nach dem Erhalt Ihrer Bestellung annehmen.</p>

            <h2 class="text-3xl font-bold">4. Widerrufsrecht</h2>
            <p>Wenn Sie Verbraucher sind (also eine natürliche Person, die die Bestellung zu einem Zweck abgibt, der weder Ihrer gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden kann), steht Ihnen nach Maßgabe der gesetzlichen Bestimmungen ein Widerrufsrecht zu.</p>
            <p>Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer 4.1 Gebrauch, so haben Sie die regelmäßigen Kosten der Rücksendung zu tragen.</p>
            <p>Im Übrigen gelten für das Widerrufsrecht die Regelungen, die im Einzelnen wiedergegeben sind in der folgenden</p>

            <b><p>Widerrufsbelehrung¹</p>

<p>Widerrufsrecht</p>

<p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>

<p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
</p>
<p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns der Stritago GmbH mittels einer eindeutigen Erklärung (zB ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (zB per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.
</p>
<p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
</p>
<p>Folgen des Widerrufs
</p>
<p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
</p>
<p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an uns oder an (hier sind gegebenenfalls der Name und die Anschrift der von Ihnen zur Entgegennahme der Ware ermächtigten Person einzufügen) zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
</p>
<p>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>

<p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
</p>
<p>- Ende der Widerrufsbelehrung -</p>

<p>(¹ Diese Widerrufsbelehrung gilt nicht für die getrennte Lieferung von Waren.)</p>
</b>

<h2 class="text-3xl font-bold">5. Preise und Versandkosten</h2>
<p>Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und sonstige Preisbestandteile.</p>
<p>Zusätzlich zu den angegebenen Preisen berechnen wir für die Lieferung innerhalb Deutschlands pauschal 3,00 Euro pro Bestellung. Die Versandkosten werden Ihnen auf den Produktseiten, im Warenkorbsystem und auf der Bestellseite nochmals deutlich mitgeteilt. 
</p>

<h2 class="text-3xl font-bold">6. Lieferung</h2>
<p>Die Lieferung erfolgt nur innerhalb Deutschlands mit DHL.</p>
<p>Die Lieferzeit beträgt bis zu 14 Tage. Auf eventuell abweichende Lieferzeiten weisen wir auf der jeweiligen Produktseite hin.</p>

<h2 class="text-3xl font-bold">7. Zahlung</h2>
<p>Die Zahlung erfolgt per Kreditkarte. </p>

<h2 class="text-3xl font-bold">8. Eigentumsvorbehalt</h2>
<p>Bis zur vollständigen Zahlung bleibt die Ware unser Eigentum.</p>

<h2 class="text-3xl font-bold">9. Streitbeilegung</h2>
<p>Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Nähere Informationen sind unter dem folgenden Link verfügbar: http://ec.europa.eu/consumers/odr. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir weder bereit noch verpflichtet</p>
    	</div>    
        
        
    </Layout>
)